.letter-container {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    letter-spacing: .5em;
    font-weight: 600;
    position: fixed;
    top: 0;
    background-color: #37003C;
    width: 100%;
}

.letter-unanswered {
    color: gray
}

.letter-correct {
    color: green
}

.letter-wrong {
    color: red
}
