.App {
  text-align: center;
}

.premier-button {
  cursor: pointer;
  font-weight: 600;
  font-size: 1.3rem;
}

.premier-button:hover {
  background-color: #55112c !important;
  color: white !important;
}

.fade-in { animation: fadeIn 5s; }

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

footer * {
  margin-bottom: .33rem;
}

footer button {
  color: #F90B6A;
  border: none;
  background-color: transparent;
  font-size: 1rem;
}

footer button:hover {
  color: #92023c;
  cursor: pointer;
}

footer span {
  color: #b30e52
}

footer span a {
  color: #ff179e;
  text-decoration: none;
}

.day-button {
  min-width: 4rem;
  min-height: 4rem;
  text-align: center;
  font-size: larger;
  max-height: 5vh;
  max-width: 5vw;
  background-color: #FF1977;
  border: none;
  cursor: pointer; 
  color: black;
}

.day-button:hover {
  background-color: rgb(55, 243, 255);
  color: black;
}