
@media (orientation: portrait) {
    .game-button {
        min-width: 50vw;
    }

    .game-button.mod {
        min-width: 40vw !important;
    }

    .player-search {
        min-width: 66vw;
    }
}

.team-button {
    margin-top: 1em;
    border: none;
    color: rgb(55, 243, 255);
    background: none;
    cursor: url(https://cdn.custom-cursor.com/db/pointer/32/Goalkeeper_Glove_Pointer.png), pointer;
    font-size: 1.4em;
    font-weight: 600;
}

.team-button:hover {
    text-decoration: underline;
}

.game-container {
    margin-top: 3em;
    display: 'flex';
}

.selectable {
    background-color: #963CFF;
    border-radius: .5em;
    padding: .5em;
    min-width: 50%;
    max-width: 90%;
    cursor: url(https://cdn.custom-cursor.com/db/pointer/32/Goalkeeper_Glove_Pointer.png), pointer;
}

.selected {
    background-color: #a15eee !important;
}

.game-button {
    background-color: #963CFF;
    color: white;
    border: none;
    font-size: 1.5em;
    border-radius: .5em;
    margin: 1em;
    padding: 1em;
    font-weight: 600;
    cursor: url(https://cdn.custom-cursor.com/db/pointer/32/Goalkeeper_Glove_Pointer.png), pointer;
}

.game-button:hover {
    background-color: #C83CFF;
}

.circle {
    border-radius: 50%;
    display: flex;
    aspect-ratio: 1 / 1;
    width: 4em;
    background-color: red;
    border: 2px solid black;
}

.circle-2 {
    border-radius: 50%;
    display: flex;
    aspect-ratio: 1 / 1;
    width: 4em;
    background-color: rgba(0,0,255,0.5);
    position: relative;
    left: 10px;
    border: 2px solid black;
}

.correct-answer {
    background-color: green !important;
}

.incorrect-answer {
    background-color: red !important;
}


.swappable {
    background-color: #7D0088;
    width: 20%;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: break-all;

}

.swappable:hover {
    background-color: rgb(156, 0, 170);
}

.swappable-selected {
    background-color: rgb(227, 89, 240);
}

table {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin: 1em;
}

.key {
    font-weight: 600;
    font-size: 1.2rem;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zonal-tr {
    height: 15vh;
}

.zonal-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    gap: 10px;
    margin: 'auto'
}



@media (orientation: landscape) {
    .zonal-grid {
        max-width: 55%;
        font-size: 2rem;
    }
    
    .key {
        font-size: 1.5rem;
    }
}